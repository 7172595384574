import React, { useEffect, useRef, useState } from 'react';
import objectHash from "object-hash";
import { Flex, Box } from "@chakra-ui/react";
import { TestimonialsComponent } from "../../api/cms/types/components";
import Testimonial from "./Testimonial";

export default function Testimonials(props: TestimonialsComponent) {
    const [testimonials, setTestimonials] = useState(props.testimonials.data);
    const scrollRef = useRef<any>(null);

    useEffect(() => {
        const interval = setInterval(() => {
            if (scrollRef.current && scrollRef.current.firstChild) {
                scrollRef.current.firstChild.style.transition = "transform 200ms linear";
                scrollRef.current.firstChild.style.transform = "translateX(-100%)";
                setTimeout(() => {
                    if (scrollRef.current && scrollRef.current.firstChild) {
                        scrollRef.current.firstChild.style.transition = "none";
                        scrollRef.current.firstChild.style.transform = "translateX(0)";
                        setTestimonials(prevTestimonials => [...prevTestimonials.slice(1), prevTestimonials[0]]);
                    }
                }, 500);
            }
        }, 2000); // Adjust interval as needed

        return () => clearInterval(interval); // Clean up on unmount
    }, [testimonials]);

    return (
        <Flex 
            direction="row" 
            overflowX="scroll" 
            justifyContent="center" 
            css={{
                scrollbarWidth: 'none', 
                msOverflowStyle: 'none',
                '&::-webkit-scrollbar': {
                    display: 'none'
                }
            }}
            p={5}
            w="full"
            ref={scrollRef}>
            {testimonials.map(x => 
                <Box key={objectHash(x)} w="auto" pr={5}>
                    <Testimonial {...x.attributes} />
                </Box>
            )}
        </Flex>
    );
}
