import { VStack } from '@chakra-ui/react';
import useHomeQuery from '../api/cms/queries/homeQuery';
import ComponentSwitch from '../components/Components';
import Intro from '../components/intro/Intro';
import { useEffect, useRef, useState } from 'react';

export default function Home() {
    const homeData = useHomeQuery();

    const vStackRef = useRef<HTMLDivElement>(null);
    const [minHeight, setMinHeight] = useState('100vh');

    useEffect(() => {
        const handleResize = () => {
            if (vStackRef.current) {
                const offsetTop = vStackRef.current.offsetTop;
                setMinHeight(`calc(100vh - ${offsetTop}px)`);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (!homeData.data) {
        return <></>;
    }

    return (<>
        <VStack 
            ref={vStackRef}
            maxWidth={"100vw"} 
            minHeight={minHeight}>
            <Intro {...homeData.data.home.data.attributes.intro} />
        </VStack>
        <ComponentSwitch {...homeData.data.home.data.attributes} />
    </>)
}