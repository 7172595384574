import { gql, useQuery } from "@apollo/client";
import { AttributesWrapper, ComponentsAttributes, DataWrapper } from "../types/core";

const packagesQuery = gql`
query {
  inPersonPackagesPage {
    data {
      attributes {
        components {
          __typename
          ... on ComponentContentRichText {
            content
          }
          ... on ComponentContentImageCarousel {
              Images {
                  data {
                      attributes {
                          formats
                      }
                  }
              }
          }
        }
      }
    }
  }
}
`;

type PackagesResponse = {
  inPersonPackagesPage: DataWrapper<AttributesWrapper<ComponentsAttributes>>
};

function useInPersonPackagesQuery() {
  return useQuery<PackagesResponse>(packagesQuery);
}

export default useInPersonPackagesQuery;
