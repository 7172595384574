import { Center, HStack, Link, Text } from "@chakra-ui/react";
import { Link as ReactRouterLink } from 'react-router-dom'
import objectHash from "object-hash";
import { FooterAttributes } from "../../api/cms/types/layout";

export default function Footer(props: Readonly<FooterAttributes>) {
    return (
        <Center>
            <HStack marginBottom={3} marginTop={10} fontWeight={"bold"} divider={<Text marginLeft={2} marginRight={2}>|</Text>}>
                {props.navigations.data.map(x =>
                    <Link 
                        key={objectHash(x)}
                        as={ReactRouterLink} 
                        to={x.attributes.path}>
                            {x.attributes.name}
                    </Link>)}
            </HStack>
        </Center>
    );
}