import { ImageCarouselComponent } from "../../api/cms/types/components";
import { useEffect, useState } from "react";
import { Box, IconButton, Image } from "@chakra-ui/react";
import { getImageUrl } from "../../api/cms/functions/images";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

export default function ImageCarousel(props: ImageCarouselComponent) {
    const [currentIndex, setCurrentIndex] = useState<number>(0);

    const handlePrev = () => {
      setCurrentIndex((prevIndex) => (prevIndex === 0 ? props.Images.data.length - 1 : prevIndex - 1));
    };
  
    const handleNext = () => {
      setCurrentIndex((prevIndex) => (prevIndex === props.Images.data.length - 1 ? 0 : prevIndex + 1));
    };

    useEffect(() => {
      const interval = setInterval(handleNext, 2500);

      return () => clearInterval(interval);
    }, []);


    return (
      <Box position="relative" width="90vw" height="600px" overflow="hidden">
        <IconButton
          icon={<ChevronLeftIcon />}
          onClick={handlePrev}
          position="absolute"
          left="0"
          top="50%"
          transform="translateY(-50%)"
          zIndex="1"
          aria-label="Go to previous image"
        />
        <Image
          src={getImageUrl(props.Images.data[currentIndex].attributes)}
          width="full"
          height="full"
          objectFit="contain"
          objectPosition="center"
        />
        <IconButton
          icon={<ChevronRightIcon />}
          onClick={handleNext}
          position="absolute"
          right="0"
          top="50%"
          transform="translateY(-50%)"
          zIndex="1"
          aria-label="Go to next image"
        />
      </Box>
    );
    
}