import { gql, useQuery } from "@apollo/client";
import { AttributesWrapper, DataWrapper } from "../types/core";
import { HomeAttributes } from "../types/home";

const homeQuery = gql`
  query {
    home {
      data {
        attributes {
          intro {
            left_intro {
              intro_title
              intro_description
              background_image {
                data {
                  attributes {
                    alternativeText
                    formats
                  }
                }
              }
              button_navigation {
                data {
                  attributes {
                    name
                    path
                  }
                }
              }
            }
            right_intro {
              intro_title
              intro_description
              background_image {
                data {
                  attributes {
                    alternativeText
                    formats
                  }
                }
              }
              button_navigation {
                data {
                  attributes {
                    name
                    path
                  }
                }
              }
            }
          }
          components {
            __typename
            ... on ComponentContentRichText {
              content
            }
            ... on ComponentInformationWhereToFindUs {
              address
            }
            ... on ComponentContentImageCarousel {
                Images {
                    data {
                        attributes {
                            formats
                        }
                    }
                }
            }
          }
        }
      }
    }
  }
`;

type HomeResponse = {
  home: DataWrapper<AttributesWrapper<HomeAttributes>>;
};

function useHomeQuery() {
  return useQuery<HomeResponse>(homeQuery);
}

export default useHomeQuery;
