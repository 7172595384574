import useAboutQuery from '../api/cms/queries/aboutQuery';
import ComponentSwitch from '../components/Components';
import FillPageVStack from '../components/layout/FillPageVStack';

export default function About() {
    const aboutQuery = useAboutQuery();

    if (!aboutQuery.data) {
        return <></>;
    }

    return (<>
        <FillPageVStack>
            <ComponentSwitch {...aboutQuery.data.about.data.attributes} />
        </FillPageVStack>  
    </>);
}