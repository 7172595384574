import { gql, useQuery } from "@apollo/client";
import { AttributesWrapper, ComponentsAttributes, DataWrapper } from "../types/core";

const query = gql`
  query {
    about {
      data {
        attributes {
          components {
            __typename
            ... on ComponentContentRichText {
              content
            }
            ... on ComponentInformationWhereToFindUs {
              address
            }
            ... on ComponentContentImageCarousel {
                Images {
                    data {
                        attributes {
                            formats
                        }
                    }
                }
            }
          }
        }
      }
    }
  }
`;

type AboutResponse = {
  about: DataWrapper<AttributesWrapper<ComponentsAttributes>>;
};

function useAboutQuery() {
  return useQuery<AboutResponse>(query);
}

export default useAboutQuery;
