import useInPersonPackagesQuery from '../api/cms/queries/inPersonPackagesQuery';
import ComponentSwitch from '../components/Components';
import FillPageVStack from '../components/layout/FillPageVStack';

export default function Packages() {
    const packagesQuery = useInPersonPackagesQuery();

    if (!packagesQuery.data) {
        return <></>;
    }

    return (<>
        <FillPageVStack>
            <ComponentSwitch {...packagesQuery.data.inPersonPackagesPage.data.attributes} />
        </FillPageVStack>  
    </>);
}