import { Box, Flex, HStack, Heading, IconButton, Link, Menu, MenuButton, MenuItem, MenuList, Text, Image, Button } from '@chakra-ui/react'
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom'
import objectHash from 'object-hash';
import { HeaderAttributes } from '../../api/cms/types/layout';

type Props = Readonly<{
    headerAttributes: HeaderAttributes,
    openWelcomePopup: () => void
}>;

export default function Header(props: Props) {
    const location = useLocation();

    const currentNavigation = props.headerAttributes.navigations.data.find(x => 
        x.attributes.path.toLowerCase() === location.pathname.toLowerCase());

    return (
        <>
            <Flex width={"100%"} direction={"row"} justifyContent={"space-between"} paddingBottom={2} background={"rgba(255, 255, 255, 0.3)"}>
                <HStack marginTop={3} marginLeft={5} fontWeight={"semibold"}  divider={<Text marginLeft={2} marginRight={2}>|</Text>}>
                    <Link fontSize={"xx-large"} as={ReactRouterLink} to={"/"}><Image src={props.headerAttributes.logo.data.attributes.formats.thumbnail.url} maxHeight={"16"} /></Link>
                </HStack>
                <HStack marginTop={3} marginRight={5} fontWeight={"semibold"}  divider={<Text marginLeft={2} marginRight={2}>|</Text>} hideBelow={"md"} aria-label='Menu'>
                    {true&& <Link 
                        as={Button} 
                        onClick={props.openWelcomePopup}>
                            {props.headerAttributes.enquire_now_button_text}
                    </Link>}
                    {props.headerAttributes.navigations.data.map(x => 
                        <Link 
                            key={objectHash(x)} 
                            as={ReactRouterLink} 
                            to={x.attributes.path}>
                                {x.attributes.name}
                        </Link>)}
                </HStack>
                <Box hideFrom={"md"}  marginTop={5} marginRight={5}>
                    <Menu>
                        {({ isOpen }) => (<>
                            <MenuButton
                                as={IconButton}
                                aria-label='Menu'
                                icon={isOpen ? <MenuOpenIcon /> : <MenuIcon />}
                                variant='outline'
                            />
                            <MenuList>
                                {props.headerAttributes.enable_enquire_now_button && <MenuItem 
                                        as={Button} 
                                        onClick={props.openWelcomePopup}
                                        _focus={{background:"none"}}>
                                        {props.headerAttributes.enquire_now_button_text}
                                    </MenuItem>}
                                {props.headerAttributes.navigations.data.map(x => 
                                    <MenuItem 
                                        key={objectHash(x)}
                                        as={ReactRouterLink} 
                                        to={x.attributes.path} 
                                        _focus={{background:"none"}}>
                                        {x.attributes.name}
                                    </MenuItem>)}
                            </MenuList>
                        </>)}
                    </Menu>
                </Box>
            </Flex>
            {currentNavigation && <HStack width={"full"} padding={10} paddingBottom={0}>
                <Heading size={"2xl"} >{currentNavigation.attributes.name}</Heading>
            </HStack>}
        </>
    );
}