import './App.css';
import { ChakraProvider } from '@chakra-ui/react';
import CmsProvider from './components/cms/CmsProvider';
import GoogleAnalytics from './components/google/GoogleAnalytics';
import Pages from './components/layout/Pages';
import { APIProvider } from '@vis.gl/react-google-maps';

function App() {
  return (
    <GoogleAnalytics>
      <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY!}>
        <CmsProvider>
          <ChakraProvider>
              <Pages />
          </ChakraProvider>
        </CmsProvider>
      </APIProvider>
    </GoogleAnalytics>
  );
}

export default App;
