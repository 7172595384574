import { Box, Text } from "@chakra-ui/react";
import { TestimonialAttributes } from "../../api/cms/types/testimonials";

export default function Testimonial(props: TestimonialAttributes) {
    return (
        <Box
            as="blockquote"
            borderLeft="4px solid"
            borderColor="gray.200"
            paddingLeft={4}
        >
            <Text fontStyle="italic" fontSize={"xl"}>{props.quote}</Text>
            <Text as="footer" mt={2} textAlign="right">
                - {props.author}
            </Text>
        </Box>
    )
}