import { Text } from "@chakra-ui/react";
import { WhereToFindUsComponent } from "../../api/cms/types/components";
import {Map} from '@vis.gl/react-google-maps';

export default function WhereToFindUs(props: WhereToFindUsComponent) {
    return (<>
        <Text fontSize={"medium"} mb={5} fontWeight={"bold"}>
            {props.address}
        </Text>
        <Map
            
            style={{width: '90vw', height: '500px'}}
            defaultCenter={{lat: 51.40180847070592, lng: -2.293900079129547}}
            defaultZoom={15}
            gestureHandling={"cooperative"}
            disableDefaultUI={false}
        />
    </>);
}