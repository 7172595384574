import { VStack } from "@chakra-ui/react";
import { ReactElement, useEffect, useRef, useState } from "react";

export default function FillPageVStack(props: Readonly<{ children: ReactElement }>)
{
    const vStackRef = useRef<HTMLDivElement>(null);
    const [minHeight, setMinHeight] = useState('100vh');

    useEffect(() => {
        const handleResize = () => {
            if (vStackRef.current) {
                const offsetTop = vStackRef.current.offsetTop;
                setMinHeight(`calc(100vh - ${offsetTop}px)`);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <VStack 
            ref={vStackRef}
            maxWidth={"100vw"} 
            minHeight={minHeight}>
            {props.children}
        </VStack>
    )
}