import objectHash from "object-hash";
import { VStack } from "@chakra-ui/react";
import { Components } from "../api/cms/types/components";
import Packages from "./packages/Packages";
import WhereToFindUs from "./whereToFindUs/WhereToFindUs";
import Testimonials from "./testimonials/Testimonials";
import RichText from "./richText/RichText";
import { ComponentsAttributes } from "../api/cms/types/core";
import ImageCarousel from "./images/ImageCarousel";

export default function ComponentSwitch(props: ComponentsAttributes) {
    
    return (<VStack>
        {props.components.map(x => {
            switch(x.__typename) {
                case "ComponentPackagesPackages":
                    return <Packages {...x} key={objectHash(x)} />
                case "ComponentInformationWhereToFindUs":
                    return <WhereToFindUs {...x} key={objectHash(x)} />
                case "ComponentTestimonialsTestimonials":
                    return <Testimonials {...x} key={objectHash(x)} />
                case "ComponentContentRichText":
                    return <RichText {...x} key={objectHash(x)} />
                case "ComponentContentImageCarousel":
                    return <ImageCarousel {...x} key={objectHash(x)} />
                default:
                    return undefined;
            }
        })}
    </VStack>)
}