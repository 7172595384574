import { Card, AspectRatio, CardBody, Heading, Box, Image, Text } from "@chakra-ui/react";
import { getImageUrl } from "../../api/cms/functions/images";
import { PackageAttributes } from "../../api/cms/types/packages";

export default function Package(props: PackageAttributes) {
    return (
        <Card boxShadow="md" borderRadius="md" overflow="hidden">
            <AspectRatio ratio={1/1} >
                <Image src={getImageUrl(props.image.data.attributes)} alt={props.name} objectFit={"cover"} />
            </AspectRatio>
            <CardBody>
                <Heading size="md" mb={2}>{props.name}</Heading>
                <Text mb={2}>{props.description}</Text>
                <Box fontWeight="bold" color="teal.500">{props.pricing}</Box>
            </CardBody>
        </Card>
    );
}