import ComponentSwitch from '../components/Components';
import useOnlinePackagesQuery from '../api/cms/queries/onlinePackagesQuery';
import FillPageVStack from '../components/layout/FillPageVStack';

export default function Packages() {
    const packagesQuery = useOnlinePackagesQuery();

    if (!packagesQuery.data) {
        return <></>;
    }

    return (<>
        <FillPageVStack>
            <ComponentSwitch {...packagesQuery.data.onlineTrainingPackagesPage.data.attributes} />
        </FillPageVStack>  
    </>);
}