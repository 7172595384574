import { VStack, Spacer, Center, Heading, HStack, Button, Box, Text } from "@chakra-ui/react";
import { getImageUrl } from "../../api/cms/functions/images";
import { HomeIntroSection } from "../../api/cms/types/home";
import { Link as ReactRouterLink } from 'react-router-dom';
import { useState } from "react";

export default function IntroSection(props: HomeIntroSection) {
    const [isHovering, setIsHovering] = useState<boolean>(false);

    return (
        <VStack 
            width={{base: "100%", md: "50%"}} 
            style={{flexGrow: 1}}
            position="relative"
            onMouseOver={() => setIsHovering(true)}
            onMouseOut={() => setIsHovering(false)}
        >
            <Box
                position="absolute"
                top={0}
                left={0}
                width="100%"
                height="100%"
                backgroundImage={props.background_image ? getImageUrl(props.background_image.data.attributes) : ""}
                backgroundSize="cover"
                filter={isHovering ? "brightness(65%)" : "none"}
                transition="filter 0.2s ease"
                zIndex={-1}
                style={{opacity: 0.85}}
            />
            <Spacer />
            <Center padding={5} textAlign={"center"}>
                <Heading size={"2xl"} color="black">
                    {props.intro_title}
                </Heading>
            </Center>
            <HStack>
                <Button 
                    backgroundColor={"black"}
                    color={"white"}
                    _hover={{ backgroundColor: "grey" }}
                    paddingX={10} 
                    variant='solid' 
                    size="lg" 
                    border={"1px"} 
                    borderColor={"gray.800"}
                    as={ReactRouterLink}
                    to={props.button_navigation.data.attributes.path}
                >
                    {props.button_navigation.data.attributes.name}
                </Button>
            </HStack>
            <Center padding={5} textAlign={"center"} style={{opacity: isHovering ? 1 : 0}}>
                <Text color="white" fontSize={"xl"}>
                    {props.intro_description}
                </Text>
            </Center>
            <Spacer />
        </VStack>
    );
}
