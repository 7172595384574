import { SimpleGrid } from "@chakra-ui/react";
import { PackagesComponent } from "../../api/cms/types/components";
import objectHash from "object-hash";
import Package from "./Package";

export default function Packages(props: PackagesComponent) {
    const packagesLength = props.packages.data.length;

    return (
        <SimpleGrid 
            columns={{ 
                base: Math.min(packagesLength, 1), 
                md: Math.min(packagesLength, 2), 
                lg: Math.min(packagesLength, 3) 
            }} 
            spacing={20}>
            {props.packages.data.map(x => 
                <Package key={objectHash(x)} {...x.attributes} />)}
        </SimpleGrid>
    );
}
