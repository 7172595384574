import { BrowserRouter, Route, Routes } from "react-router-dom";
import useLayoutQuery from "../../api/cms/queries/layoutQuery";
import Home from "../../pages/Home";
import About from "../../pages/About";
import InPersonPackages from "../../pages/InPersonPackages";
import OnlinePackages from "../../pages/OnlinePackages";
import { WelcomePopup } from "../popups/WelcomePopup";
import Header from "./Header";
import Footer from "./Footer";
import { useDisclosure } from "@chakra-ui/react";
import { useEffect } from "react";

function Pages() {
    const layoutQuery = useLayoutQuery();

    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        onOpen(); // Open the modal when the component mounts
    }, [onOpen]);

    if (!layoutQuery.data) {
        return (<></>);
    }

    return (<span style={{overflowX: "hidden", maxWidth: "100%"}}>
        <WelcomePopup 
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            welcomePopupAttributes={layoutQuery.data.welcomePopup.data.attributes} />
        <BrowserRouter>
            <Header 
                openWelcomePopup={onOpen}
                headerAttributes={layoutQuery.data.header.data.attributes} />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/gympackages" element={<InPersonPackages />} />
                <Route path="/onlinepackages" element={<OnlinePackages />} />
            </Routes>
            <Footer {...layoutQuery.data.footer.data.attributes} />
        </BrowserRouter>
    </span>);
}

export default Pages;