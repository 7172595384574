import { Stack } from "@chakra-ui/react";
import { HomeIntro } from "../../api/cms/types/home";
import IntroSection from "./IntroSection";

export default function Intro(props: HomeIntro) {
    return (
        <Stack 
            spacing={0} 
            width={"full"} 
            height={"full"} 
            flexGrow={1} 
            alignItems={"stretch"}
            direction={{base: "column", md: "row"}}>
            <IntroSection {...props.left_intro} />
            <IntroSection {...props.right_intro} />
        </Stack>
    );
}