import { gql, useQuery } from "@apollo/client";
import { AttributesWrapper, DataWrapper } from "../types/core";
import { FooterAttributes, HeaderAttributes, WelcomePopupAttributes } from "../types/layout";

const layoutQuery = gql`
  query {
    header {
      data {
        attributes {
          logo {
            data {
              attributes {
                formats
                alternativeText
              }
            }
          }
          navigations {
            data {
              attributes {
                name
                path
              }
            }
          }
          enable_equire_now_button
          enquire_now_button_text
        }
      }
    }
    footer {
      data {
        attributes {
          navigations {
            data {
              attributes {
                name
                path
              }
            }
          }
        }
      }
    }
    welcomePopup {
      data {
        attributes {
          title
          introduction
          image {
            data {
              attributes {
                formats
                alternativeText
              }
            }
          }
        }
      }
    }
  }
`;

export type LayoutResponse = {
  header: DataWrapper<AttributesWrapper<HeaderAttributes>>;
  footer: DataWrapper<AttributesWrapper<FooterAttributes>>;
  welcomePopup: DataWrapper<AttributesWrapper<WelcomePopupAttributes>>
};

function useLayoutQuery() {
  return useQuery<LayoutResponse>(layoutQuery);
}

export default useLayoutQuery;
