import { FormEvent, FormEventHandler, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Text,
  Spinner,
  useToast
} from '@chakra-ui/react';
import { WelcomePopupAttributes } from '../../api/cms/types/layout';
import axios from 'axios';

type Props = {
    welcomePopupAttributes: WelcomePopupAttributes,
    onClose: () => void,
    onOpen: () => void,
    isOpen: boolean
}

export function WelcomePopup(props: Props) {

  const toast = useToast();

  const [name, setName] = useState<string>("");
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  function close() {
    setName("");
    setEmailAddress("");
    setPhoneNumber("");
    setMessage("");
    props.onClose();
  }

  const submit: FormEventHandler<HTMLFormElement> = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);

    const data = {
        name,
        emailAddress,
        phoneNumber,
        message
    };

    try {
        const response = await axios.post(process.env.REACT_APP_ENQUIRE_NOW_ENDPOINT!, data);

        if (response.status !== 200) {
            throw response.statusText;
        }

        toast({
          title: 'Thank you for getting in touch',
          description: "A member of our team will get back to you soon.",
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
    } catch(error) {
        console.error(error);
    } finally {
      setIsSubmitting(false);
      close();
    }
  };

  return (
    <>
      <Modal isOpen={props.isOpen} onClose={props.onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{props.welcomePopupAttributes.title}</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={submit}>
            <ModalBody>
              <Text mb={5}>{props.welcomePopupAttributes.introduction}</Text>

              <FormControl id="name" isRequired>
                <FormLabel>Name</FormLabel>
                <Input 
                  placeholder="Your name" 
                  value={name} 
                  onChange={x => setName(x.target.value)} />
              </FormControl>

              <FormControl id="email" isRequired mt={4}>
                <FormLabel>Email Address</FormLabel>
                <Input 
                  type="email" 
                  placeholder="Your email address" 
                  value={emailAddress} 
                  onChange={x => setEmailAddress(x.target.value)} />
              </FormControl>

              <FormControl id="phone" mt={4}>
                <FormLabel>Phone Number</FormLabel>
                <Input 
                  placeholder="Your phone number" 
                  value={phoneNumber} 
                  onChange={x => setPhoneNumber(x.target.value)} />
              </FormControl>

              <FormControl id="message" mt={4}>
                <FormLabel>Message</FormLabel>
                <Textarea 
                  placeholder="Your message" 
                  value={message} 
                  onChange={x => setMessage(x.target.value)} />
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} type="submit" disabled={isSubmitting} rightIcon={isSubmitting ? <Spinner size='xs' /> : undefined}>
                Submit
              </Button>
              <Button variant="ghost" onClick={close}>Cancel</Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
}
