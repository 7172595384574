import { ImageAttributes } from "../types/core";

export function getImageUrl(image: ImageAttributes) {
    const formats = image.formats;

    const formatKeys = Object.keys(formats);

    const format = formats[formatKeys[0]];

    return format.url;
}